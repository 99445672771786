import "./app.css"

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Router from "./routers";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

export default function Root() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename={"/event-booking"}>
          <Router />
        </BrowserRouter>
        <ToastContainer />
      </QueryClientProvider>
    </>
  );
}
