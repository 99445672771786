import React from "react";
import DashboardIcon from "../assets/icons/dashboard.svg";
import EventIcon from "../assets/icons/eventIcon.svg";
import MovieIcon from "../assets/icons/movieIcon.svg";
import AttandeesIcon from "../assets/icons/workIcon.svg";
import AccountIcon from "../assets/icons/accountIcon.svg";
import { NavLink, Outlet } from "react-router-dom";

//@ts-ignore
import { Layout } from "@dibtech/styleguide";

const navigation: any = [
  { name: "Dashboard", href: "/", icon: DashboardIcon },
  { name: "My Event", href: "/my-events", icon: EventIcon },
  { name: "My Movies", href: "/my-movies", icon: MovieIcon },
  { name: "My Attandees", href: "/my-attandees", icon: AttandeesIcon },
  { name: "My Account", href: "/my-account", icon: AccountIcon },
];

const DashboardLayout = () => {
  return (
    
    <Layout
      children={<Outlet />}
      navigationComponent={
        <nav className="px-2 space-y-1">
          {navigation?.map((item: any) => (
            <NavLink
              key={item.name}
              to={item.href}
              className={({ isActive, isPending }) => {
                return `${isActive ? "bg-[#5b6ab0] text-white" : isPending ? "text-indigo-100 hover:bg-indigo-600" : ""} group flex gap-2 items-center px-2 py-2 text-sm font-medium rounded-md`;
              }}
            >
              <img src={item.icon} alt="" className="text-indigo-300" />
              {item.name}
            </NavLink>
          ))}
        </nav>
      }  
    />   
  );
};

export default DashboardLayout;
