import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PageNotFound from "../pages/ErrorPage/PageNotFound";
import DashboardLayout from "../layouts/DashboardLayout";
import Dashboard from "../pages/Dashboard/Dashboard";
import Events from "../pages/MyEvents/Event";
import Movies from "../pages/MyMovies/Movies";
import Attandees from "../pages/Attandees/Attandees";
import Accounts from "../pages/Accounts/Accounts";
import AddNewForm from "../pages/AddNewForm/AddNewForm";

// import AccessControl from "../helpers/accessControl";
// import PermissionDenied from "../pages/Error/PermissionDenied";

// https://adarshaacharya.com.np/blog/role-based-auth-with-react-router-v6

/**
 * Top level application router
 *
 * @returns {JSX.Element}
 */
const Router = () => {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <DashboardLayout />
          </PrivateRoute>
        }
      >
        <Route path={"/"} element={<Dashboard />} />
        <Route path={"/my-events"} element={<Events />} />
        <Route path={"/my-movies"} element={<Movies />} />
        <Route path={"/my-attandees"} element={<Attandees />} />
        <Route path={"/my-account"} element={<Accounts />} />  
        <Route path={"/add-event"} element={<AddNewForm />} />  
        <Route path={"/add-movie"} element={<AddNewForm />} />  
        <Route path="/*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default Router;
