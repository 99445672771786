import React from 'react'
import loadable from "@loadable/component";

const EventComponent = loadable(() => import("../../components/Events/Events"));

const Event = () => {
  return (
    <div><EventComponent/></div>
  )
}

export default Event


