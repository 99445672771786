import React from 'react'
import loadable from "@loadable/component";

const AttandeesComponent = loadable(() => import("../../components/Attandees/Attandees"));

const Attandees = () => {
  return (
    <div>
      <AttandeesComponent />
    </div>
  )
}

export default Attandees


