import React from 'react'
import loadable from "@loadable/component";

const AddNewFormComponent = loadable(() => import("../../components/AddNewForm/AddNewForm"));

const AddNewForm = () => {

  return (
    <div>
      <AddNewFormComponent />
    </div>
  )
}

export default AddNewForm


