import React from 'react'
import loadable from "@loadable/component";

const MoviesComponent = loadable(() => import("../../components/Movies/Movies"));

const Movies = () => {
  return (
    <div>
      <MoviesComponent />
    </div>
  )
}

export default Movies