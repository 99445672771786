import React from 'react'
import loadable from "@loadable/component";

const AccountsComponent = loadable(() => import("../../components/Accounts/Accounts"));

const Accounts = () => {
  return (
    <div>
      <AccountsComponent />
    </div>
  )
}

export default Accounts


